<script>
export default {
    
}
</script>

<template>
    <div class="card card-height-100">
                                    <div class="card-header align-items-center d-flex">
                                        <h4 class="card-title mb-0 flex-grow-1">Top Pages</h4>
                                        <div class="flex-shrink-0">
                                            <div class="dropdown card-header-dropdown">
                                                <a class="text-reset dropdown-btn" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                   <span class="text-muted fs-16"><i class="mdi mdi-dots-vertical align-middle"></i></span>
                                                </a>
                                                <div class="dropdown-menu dropdown-menu-end">
                                                    <a class="dropdown-item" href="#">Today</a>
                                                    <a class="dropdown-item" href="#">Last Week</a>
                                                    <a class="dropdown-item" href="#">Last Month</a>
                                                    <a class="dropdown-item" href="#">Current Year</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div><!-- end card header -->
                                    <div class="card-body">
                                        <div class="table-responsive table-card">
                                            <table class="table align-middle table-borderless table-centered table-nowrap mb-0">
                                                <thead class="text-muted table-light">
                                                    <tr>
                                                        <th scope="col" style="width: 62;">Active Page</th>
                                                        <th scope="col">Active</th>
                                                        <th scope="col">Users</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <a href="javascript:void(0);">/themesbrand/skote-25867</a>
                                                        </td>
                                                        <td>99</td>
                                                        <td>25.3%</td>
                                                    </tr><!-- end -->
                                                    <tr>
                                                        <td>
                                                            <a href="javascript:void(0);">/dashonic/chat-24518</a>
                                                        </td>
                                                        <td>86</td>
                                                        <td>22.7%</td>
                                                    </tr><!-- end -->
                                                    <tr>
                                                        <td>
                                                            <a href="javascript:void(0);">/skote/timeline-27391</a>
                                                        </td>
                                                        <td>64</td>
                                                        <td>18.7%</td>
                                                    </tr><!-- end -->
                                                    <tr>
                                                        <td>
                                                            <a href="javascript:void(0);">/themesbrand/minia-26441</a>
                                                        </td>
                                                        <td>53</td>
                                                        <td>14.2%</td>
                                                    </tr><!-- end -->
                                                    <tr>
                                                        <td>
                                                            <a href="javascript:void(0);">/dashon/dashboard-29873</a>
                                                        </td>
                                                        <td>33</td>
                                                        <td>12.6%</td>
                                                    </tr><!-- end -->
                                                    <tr>
                                                        <td>
                                                            <a href="javascript:void(0);">/doot/chats-29964</a>
                                                        </td>
                                                        <td>20</td>
                                                        <td>10.9%</td>
                                                    </tr><!-- end -->
                                                    <tr>
                                                        <td>
                                                            <a href="javascript:void(0);">/minton/pages-29739</a>
                                                        </td>
                                                        <td>10</td>
                                                        <td>07.3%</td>
                                                    </tr><!-- end -->
                                                </tbody><!-- end tbody -->
                                            </table><!-- end table -->
                                        </div><!-- end -->
                                    </div><!-- end cardbody -->
                                </div><!-- end card -->
</template>